<template>
  <HandleBase>
    <div class="tbl_handle tbl_scroll">
      <div class="inner_scroll" :style="{ height: scrollHeight }">
        <table>
          <thead>
            <tr>
              <th v-if="hasList" style="width: 16px">
                <CheckboxOnlyOne :isSelected.sync="isSelectedSync" />
              </th>
              <th :style="hasList ? 'width: 336px' : 'width: 388px'">대분류 설정</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="!hasList">
              <td class="td_empty h473">
                {{
                  hasItemList
                    ? "취급품목을 선택하여 대분류를 설정해보세요"
                    : "설정 된 대분류가 없습니다."
                }}
              </td>
            </tr>
            <CategoryListLine
              v-for="item in kindList0"
              v-else
              :key="item.cateNum"
              :rowData="item"
              :checkedNames.sync="checkedNamesSync"
            />
          </tbody>
        </table>
      </div>
    </div>
    <!-- <slot v-if="this.dataList.length > 0" name="util" /> -->
    <div v-if="hasList" class="area_util">
      <div class="area_right">
        <button type="button" class="btn_small btn_secondary" @click="onClickCancel">취소</button>
        <button type="button" class="btn_small btn_tertiary" @click="onClickComplete">완료</button>
      </div>
    </div>
  </HandleBase>
</template>

<script>
import HandleBase from "@/components/admin/system/handle/HandleBase";
import CheckboxOnlyOne from "@/components/common/checkbox/CheckboxOnlyOne";
import CategoryListLine from "./CategoryListLine";

export default {
  name: "CategoryList",
  components: {
    HandleBase,
    CheckboxOnlyOne,
    CategoryListLine,
  },
  props: {
    hasList: Boolean,
    hasItemList: Boolean,
    scrollHeight: String,
    kindList0: Array,
    selectedId: Number,
    checkedNames: Array,
    isSelectedCategoryAll: Boolean,
  },
  computed: {
    checkedNamesSync: {
      get() {
        return this.checkedNames;
      },
      set(value) {
        this.$emit("update:checkedNames", value);
      },
    },
    isSelectedSync: {
      get() {
        return this.isSelectedCategoryAll;
      },
      set(value) {
        this.$emit("update:isSelectedCategoryAll", value);
      },
    },
  },
  methods: {
    onClickCancel() {
      this.$emit("onClickCancel");
    },
    onClickComplete() {
      this.$emit("onClickComplete");
    },
  },
};
</script>
<style scoped>
.tbl_handle thead th {
  padding: 15px 4px;
}
.tbl_handle thead th:last-child {
  padding-right: 56px;
}
.tbl_handle thead th:only-child {
  padding-right: 24px;
}
</style>
