<template>
  <tr :class="{ tr_select: isSelected }" @click="onClickRow">
    <td>
      <HandleEditItem
        v-if="isItemModifying"
        :titleDefault="itemTitle"
        @onCompleteWrite="onCompleteModifyItem"
      />
      <div v-else-if="isModifyMode" class="area_left">
        <!--  && currentModifyCateNum !== item.handleNum -->
        <button
          v-if="isModifyMode"
          type="button"
          class="btn_handle"
          @click.stop="onClickRemoveItem"
        >
          <span class="ico_purchase ico_delete">삭제</span>
        </button>
        <button
          v-if="isModifyMode"
          type="button"
          class="btn_handle"
          @click.stop="onClickModifyItem"
        >
          <span class="ico_purchase ico_edit">수정</span>
        </button>
      </div>
      <span v-if="!isItemModifying" class="txt_handle">{{ itemTitle }}</span>
    </td>
    <td v-if="linkDesc" :class="['td_fold', { open: isOpen }]">
      <span class="txt_ellip">{{ linkDesc }}</span>
      <span v-if="isEllip" class="ico_purchase ico_arr_ellip" @click.stop="onClickFold"
        >펼치기 / 접기</span
      >
    </td>
    <td v-else class="td_empty_item">선택 된 대분류가 없습니다.</td>
  </tr>
</template>

<script>
import CheckboxItem from "@/components/common/checkbox/CheckboxItem";
import HandleWriteItem from "@/components/admin/system/handle/HandleWriteItem";
import HandleEditItem from "@/components/admin/system/handle/HandleEditItem";
import ApiService from "@/services/ApiService";

export default {
  name: "HandleListLine",
  components: {
    CheckboxItem,
    HandleWriteItem,
    HandleEditItem,
  },
  props: {
    rowData: Object,
    isSelected: Boolean,
    isModifyMode: Boolean,
    currentModifyId: Number,
    isEllip: Boolean,
  },
  data() {
    return {
      isOpen: false,
    };
  },
  computed: {
    itemTitle() {
      if (!this.rowData) return "";

      return this.rowData.itemTitle;
    },
    linkDesc() {
      if (!this.rowData) return "";

      return this.rowData.linkDesc;
    },
    handleNum() {
      if (!this.rowData) return null;

      return this.rowData.handleNum;
    },
    isItemModifying() {
      if (this.currentModifyId === null) return false;

      return this.currentModifyId === this.handleNum;
    },
    // isEllip() {
    //   if( !this.linkDesc )  return false;
    //   // return this.linkDesc.length > 80;
    // }
  },
  methods: {
    onClickFold() {
      this.isOpen = !this.isOpen;
    },
    onClickRow() {
      this.$emit("onClickRow", this.rowData);
    },
    onClickRemoveItem() {
      this.$emit("onClickRemoveItem", this.rowData);
    },
    onClickModifyItem() {
      this.$emit("onClickModifyItem", this.rowData);
    },
    onCompleteModifyItem(value) {
      this.$emit("onCompleteModifyItem", this.rowData, value);
    },
  },
};
</script>
<style scoped>
.tbl_handle .td_empty_item {
  color: #bbb;
  text-align: center;
  vertical-align: middle;
}
.tbl_handle .txt_handle {
  display: block;
  padding: 7px 0;
}
.tbl_handle .btn_handle {
  display: inline-block;
  padding: 6px 0;
  vertical-align: top;
}
.tbl_handle .btn_handle + .btn_handle {
  margin-left: 20px;
}
.tbl_handle .area_left + .txt_handle {
  overflow: hidden;
  padding-left: 8px;
}

/* 말줄임 버전 */
.tbl_handle tbody td.td_fold {
  position: relative;
  overflow: hidden;
  height: 18px;
  padding: 15px 64px 15px 12px;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: break-all;
}
.tbl_handle tbody td.td_fold .ico_arr_ellip {
  position: absolute;
  top: 13px;
  right: 20px;
}
.tbl_handle tbody td.td_fold.open {
  overflow: auto;
  white-space: inherit;
  text-overflow: inherit;
  word-break: inherit;
}

/* 리스트 table_active */
.tbl_handle tbody .tr_select td {
  position: relative;
  z-index: 10;
  border-bottom-color: #5551ce;
  background: #fbfbfb url("~@/assets/images/bg_table_active.png") repeat-x;
  background-size: 1px 1px;
}
.tbl_handle tbody .tr_select td:first-child {
  border-left: 1px solid #5551ce;
}
.tbl_handle tbody .tr_select td:last-child {
  border-right: 1px solid #5551ce;
}
</style>
