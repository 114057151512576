<template>
  <div class="handle_comm">
    <slot />
  </div>
</template>

<script>
export default {
  name: "HandleBase",
};
</script>

<style scoped>
.handle_comm {
  float: left;
  position: relative;
  padding-bottom: 49px;
  border-top: 1px solid #222;
}
.handle_comm + .handle_comm {
  margin-left: 40px;
}

.tbl_handle {
  background-color: #f9fafd;
}
.tbl_handle /deep/ table {
  width: 100%;
  border: 0 none;
  border-spacing: 0;
  table-layout: fixed;
}
.tbl_handle /deep/ thead th {
  padding: 15px 12px;
  border-bottom: 1px solid #e1e1e1;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;

  background-color: #fbfbfb;
  color: #222;
}
.tbl_handle /deep/ thead th:first-child,
.tbl_handle /deep/ tbody td:first-child {
  padding-left: 24px;
}
.tbl_handle /deep/ thead th:last-child,
.tbl_handle /deep/ tbody td:last-child {
  padding-right: 24px;
}
.tbl_handle /deep/ tbody td {
  height: 32px;
  padding: 8px 12px;
  border-bottom: 1px solid #e1e1e1;
  line-height: 18px;
  background-color: #fff;
  text-align: left;
  word-break: break-all;

  vertical-align: top;
}
/* 추가 및 편집시 */
.tbl_handle /deep/ .item_add .btn_save {
  padding: 4px 17px 4px 0;
}
.tbl_handle /deep/ .item_add .desc_tip {
  margin: 0;
  padding: 8px 0 0 8px;
  color: #bbb;
}
.tbl_handle /deep/ .item_add .desc_tip em.on {
  color: #888;
}
/* 빈 리스트 */
.handle_comm .td_empty {
  background-color: #f9fafd;
  color: #5551ce;
  text-align: center;
  vertical-align: middle;
}
.handle_comm .td_empty em {
  font-weight: 500;
  text-decoration: underline;
}
/* 리스트 하단 컨트롤러 */
.handle_comm .area_util {
  background-color: #fbfbfb;
}
.handle_comm .area_util button + button {
  margin-left: 8px;
}
</style>
