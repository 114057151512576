<template>
  <PageWithLayout>
    <div class="section_comm">
      <div class="head_section">
        <h2 id="kakaoBody" class="tit_section">취급 품목 관리</h2>
      </div>
      <div class="body_section">
        <TableHead>
          <template v-slot:headLeft>
            <p class="desc_tip">
              <span class="ico_purchase ico_exclam" />취급 품목 추가 및 편집이 가능하며, 대분류
              설정은 개수 제한 없이 선택 가능합니다.
            </p>
          </template>
        </TableHead>
        <div class="group_handle">
          <HandleList
            class="w812"
            scrollHeight="490px"
            :dataList="items"
            :hasList="hasItemList"
            :selectedId="selectedId"
            :isModifyMode="isModifyMode"
            :isAddMode="isAddMode"
            :currentModifyId="currentModifyId"
            @onClickRow="onClickRow"
            @onClickAddMode="onClickAddMode"
            @onCompleteAddWrite="onCompleteAddWrite"
            @onClickModifyMode="onClickModifyMode"
            @onCompleteModifyMode="onCompleteModifyMode"
            @onClickRemoveItem="onClickRemoveItem"
            @onClickModifyItem="onClickModifyItem"
            @onCancelAddMode="onCancelAddMode"
            @onCompleteModifyItem="onCompleteModifyItem"
          />
          <CategoryList
            class="w388"
            scrollHeight="490px"
            :kindList0="kindList0"
            :hasList="hasCategoryList"
            :hasItemList="hasItemList"
            :selectedId="selectedId"
            :checkedNames.sync="checkedNames"
            :isSelectedCategoryAll.sync="isSelectedCategoryAll"
            @update:isSelectedCategoryAll="onChangeCategoryAll"
            @onClickCancel="onClickCancelCategory"
            @onClickComplete="onClickCompleteCategory"
          />
        </div>
      </div>
    </div>
  </PageWithLayout>
</template>

<script>
import PageWithLayout from "@/components/layout/PageWithLayout";
import TableHead from "@/components/shared/TableHead";
import HandleList from "@/components/admin/system/handle/HandleList";
import CategoryList from "@/components/admin/system/handle/CategoryList";

import ApiService from "@/services/ApiService";
import PageMixin from "@/mixins/PageMixin";
import { CATEGORY_ACTION } from "@/store/modules/category/action";

export default {
  name: "Handle",
  components: {
    PageWithLayout,
    TableHead,
    HandleList,
    CategoryList,
  },
  mixins: [PageMixin],
  data() {
    return {
      items: [],
      selectedId: null,
      isModifyMode: false,
      isAddMode: false,
      currentModifyId: null,
      checkedNames: [],
      isSelectedCategoryAll: false,
    };
  },
  computed: {
    kindList0() {
      const arr = this.$store.state.category.categoryList0;

      // 최상위 cateNum 은 0.
      const cateNum = 0;

      const itemCate = arr.find((item) => item.parentCateNum === cateNum);
      if (!itemCate) return [];

      return itemCate.dataList;
    },
    hasItemList() {
      if (this.items.length < 1) return false;

      // if( !this.selectedId )  return false;

      return true;
    },
    hasCategoryList() {
      if (this.kindList0.length < 1) return false;

      if (!this.selectedId) return false;

      return true;
    },
  },
  beforeMount() {
    this.$store.dispatch(CATEGORY_ACTION.GET_LIST_0).then(() => {
      this.getHandleItems();
    });
  },
  methods: {
    onClickRow(itemData) {
      // 추가 모드일 때 편집 불가능하게.
      if (this.isAddMode) return;
      // 아이템 수정 중일 때 편집 불가능.
      if (this.currentModifyId !== null) return;

      if (this.selectedId === itemData.handleNum) return;

      this.changeSelectedId(itemData.handleNum);
    },
    changeSelectedId(handleNum) {
      this.selectedId = handleNum;

      this.checkedNames = [];

      this.isSelectedCategoryAll = false;

      this.getCategories(this.selectedId);
    },
    onClickAddMode() {
      // 추가 모드일 때 편집 불가능하게.
      this.selectedId = null;

      this.isAddMode = true;
    },
    onCancelAddMode() {
      this.isAddMode = false;
    },
    onCompleteAddWrite(title) {
      this.saveItem(title);

      this.isAddMode = false;
    },
    onClickModifyMode() {
      this.isModifyMode = true;
    },
    onCompleteModifyMode() {
      this.isModifyMode = false;
      this.currentModifyId = null;
    },
    onClickRemoveItem(itemData) {
      this.deleteItem(itemData.handleNum);
    },
    onClickModifyItem(itemData) {
      // 아이템 수정 중일 때 편집 불가능.
      this.selectedId = null;

      this.currentModifyId = itemData.handleNum;
    },
    onCompleteModifyItem(itemData, value) {
      this.modifyItem(itemData.handleNum, value);

      this.currentModifyId = null;
    },
    onClickCancelCategory() {
      this.selectedId = null;
    },
    onClickCompleteCategory() {
      const arr = this.getCategoriesToSend(this.checkedNames);

      this.saveCategories(this.selectedId, arr);

      this.selectedId = null;
    },
    onChangeCategoryAll() {
      if (!this.isSelectedCategoryAll) {
        this.checkedNames = [];
        return;
      }

      this.checkedNames = this.kindList0.map((item) => item.cateNum);
    },
    getCategoriesToSend(checkedNames) {
      const ret = checkedNames.map((itemId) => {
        const found = this.kindList0.find((cateItem) => cateItem.cateNum === itemId);
        return {
          cateDate: found.cateDate,
          cateNum: found.cateNum,
        };
      });

      return ret;
    },
    async getHandleItems() {
      const path = `${this.$apiPath.HANDLE}`;
      const result = await ApiService.shared.getData(path);

      if (!result.data) {
        if (result.code === "204") {
          this.items = [];
        } else {
          this.alert(result.text);
        }
        return;
      }

      this.items = result.data;

      this.getHandleItemLinks();
    },
    async getHandleItemLinks() {
      const path = `${this.$apiPath.HANDLE_LINK}`;
      const result = await ApiService.shared.getData(path);

      // if( !result.data ) return;

      const links = result.data || [];

      this.setLinks(links);
    },
    setLinks(links) {
      this.items = this.items.map((item) => {
        const found = links.find((linkItem) => item.handleNum === linkItem.handleNum);

        const ret = found ? Object.assign({}, item, found) : item;

        return ret;
      });
    },
    async saveItem(title) {
      const path = `${this.$apiPath.HANDLE}`;
      const obj = {
        itemTitle: title,
      };

      const result = await ApiService.shared.postData(path, obj);

      if (!result.data) {
        this.alert(result.text);
        return;
      }

      // 목록 다시 안가져오고 그냥 수정하겠다.
      this.items = this.items.concat(result.data);
    },
    async deleteItem(id) {
      const path = `${this.$apiPath.HANDLE}/${id}`;
      const result = await ApiService.shared.deleteData(path);

      if (result.code !== "200") {
        this.alert(result.text);
        return;
      }

      if (id === this.selectedId) {
        this.selectedId = null;
      }

      // 목록 다시 안가져오고 그냥 수정하겠다.
      this.items = this.items.filter((item) => item.handleNum !== id);
    },
    async modifyItem(id, title) {
      const path = `${this.$apiPath.HANDLE}/${id}`;
      const obj = {
        itemTitle: title,
      };

      const result = await ApiService.shared.putData(path, obj);

      if (!result.data) {
        this.alert(result.text);
        return;
      }

      // 목록 다시 안가져오고 그냥 수정하겠다.
      this.items = this.items.map((item) => {
        const isModifyItem = item.handleNum === id;

        if (!isModifyItem) return item;

        return Object.assign({}, item, result.data);
      });
    },
    async saveCategories(selectedId, arr) {
      const path = `${this.$apiPath.HANDLE}/${selectedId}`;

      const result = await ApiService.shared.postData(path, arr);

      if (!result.data) {
        this.alert(result.text);
        return;
      }

      // 목록 다시 안가져오고 그냥 수정하겠다.
      // 수정하고 나서 필요한 게 link - desc.
      this.items = this.items.map((item) => {
        const isModifyItem = item.handleNum === selectedId;

        if (!isModifyItem) return item;

        const ret = Object.assign({}, item, { linkDesc: result.data.linkDesc });
        return ret;
      });

      // this.getHandleItemLinks();
    },
    async getCategories(handleNum) {
      const path = `${this.$apiPath.HANDLE}/${handleNum}`;
      const result = await ApiService.shared.getData(path);

      if (!result.data) {
        this.alert(result.text);
        return;
      }

      const { categoryHandleItemDetailList } = result.data;

      this.checkedNames = this.getCheckedNames(categoryHandleItemDetailList);
    },
    getCheckedNames(categoryHandleItemDetailList) {
      if (!categoryHandleItemDetailList) return [];

      if (this.kindList0.length < 1) return [];

      const ret = categoryHandleItemDetailList.map((item) => item.cateNum);

      return ret;
    },
  },
};
</script>
<style scoped>
.group_handle {
  overflow: hidden;
}
</style>
