<template>
  <HandleBase>
    <div class="tbl_handle tbl_scroll">
      <div class="inner_scroll" :style="{ height: scrollHeight }">
        <table>
          <colgroup v-if="isModifyMode">
            <col style="width: 490px" />
            <!-- 취급품목 -->
            <col />
            <!-- 대분류 322 -->
          </colgroup>
          <colgroup v-else>
            <col style="width: 366px" />
            <!-- 취급품목 -->
            <col />
            <!-- 대분류 446 -->
          </colgroup>
          <thead>
            <tr>
              <th :style="isModifyMode ? 'width: 490px' : 'width: 366px'">취급품목</th>
              <th :style="isModifyMode ? 'width: 322px' : 'width: 446px'">대분류</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="isAddMode">
              <td colspan="2" class="align_left">
                <HandleWriteItem @onCompleteAddWrite="onCompleteAddWrite" />
              </td>
            </tr>
            <tr v-if="!hasList && !isAddMode">
              <td colspan="2" class="td_empty h473">
                선택 가능한 취급품목이 없습니다. 취급품목을 <em>추가</em> 해주세요.
              </td>
            </tr>
            <HandleListLine
              v-for="(item, index) in dataList"
              v-else
              :key="index"
              :isEllip="isEllipList[index]"
              :rowData="item"
              :isSelected="selectedId === item.handleNum"
              :isModifyMode="isModifyMode"
              :currentModifyId="currentModifyId"
              @onClickRow="onClickRow"
              @onClickRemoveItem="onClickRemoveItem"
              @onClickModifyItem="onClickModifyItem"
              @onCompleteModifyItem="onCompleteModifyItem"
            />
          </tbody>
        </table>
      </div>
    </div>
    <div class="area_util">
      <div class="area_right">
        <button
          v-if="!isAddMode && !isModifyMode"
          type="button"
          class="btn_small btn_fourthly"
          @click="onClickAddMode"
        >
          추가
        </button>
        <button
          v-if="!isAddMode && !isModifyMode && hasList"
          type="button"
          class="btn_small btn_fourthly"
          @click="onClickModifyMode"
        >
          편집
        </button>
        <button
          v-if="isAddMode"
          type="button"
          class="btn_small btn_secondary"
          @click="onCancelAddMode"
        >
          취소
        </button>
        <button
          v-if="isModifyMode"
          type="button"
          class="btn_small btn_tertiary"
          @click="onCompleteModifyMode"
        >
          완료
        </button>
      </div>
    </div>
  </HandleBase>
</template>

<script>
import HandleBase from "@/components/admin/system/handle/HandleBase";
import HandleListLine from "./HandleListLine";
import HandleWriteItem from "../handle/HandleWriteItem";

export default {
  name: "HandleList",
  components: {
    HandleBase,
    HandleListLine,
    HandleWriteItem,
  },
  props: {
    hasList: Boolean,
    dataList: Array,
    scrollHeight: String,
    selectedId: Number,
    isModifyMode: Boolean,
    isAddMode: Boolean,
    currentModifyId: Number,
  },
  data() {
    return {
      isEllipList: [],
    };
  },
  computed: {},
  watch: {
    dataList(newVal, oldVal) {
      if (!newVal || newVal.length === 0) return;

      this.measureEllip();
    },
  },
  mounted() {
    this.measureEllip();
  },
  methods: {
    measureEllip() {
      this.$nextTick(function () {
        const $tdFoldItme = $("td + td");
        const $MaxWidth = $tdFoldItme.width();
        const $textWidths = $tdFoldItme.map(function () {
          $(this).removeClass("open");
          if ($(this)[0].className.indexOf("td_fold") > -1) {
            return $MaxWidth < $(this).children("span").width();
          } else {
            return false;
          }
        });
        this.isEllipList = $textWidths;
      });
    },
    onClickRow(itemData) {
      this.$emit("onClickRow", itemData);
    },
    onClickRemoveItem(itemData) {
      this.$emit("onClickRemoveItem", itemData);
    },
    onClickAddMode() {
      this.$emit("onClickAddMode");
      // this.addValue = '';
      // this.isAddMode = true;
      // this.currentModifyCateNum = null;
    },
    onClickModifyMode() {
      this.$emit("onClickModifyMode");
      this.measureEllip();
      // this.isAddMode = false;
      // this.isModifyMode = true;
      // this.currentModifyCateNum = null;
    },
    onCompleteModifyMode() {
      this.$emit("onCompleteModifyMode");
      this.measureEllip();
      // this.isModifyMode = false;
      // this.isAddMode = false;
      // this.currentModifyCateNum = null;
    },
    onClickModifyItem(itemData) {
      this.$emit("onClickModifyItem", itemData);

      // this.modifyValue = item.itemTitle;
      // this.currentModifyCateNum = item.handleNum;
    },
    onCompleteAddWrite(title) {
      this.$emit("onCompleteAddWrite", title);
    },
    onCancelAddMode() {
      this.$emit("onCancelAddMode");
    },
    onCompleteModifyItem(itemData, value) {
      this.$emit("onCompleteModifyItem", itemData, value);
    },
  },
};
</script>
