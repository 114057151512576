<template>
  <div class="item_add">
    <button type="button" class="btn_save" @click="onCompleteAddWrite">
      <span class="ico_purchase ico_check">저장</span>
    </button>
    <Input
      class="w370"
      :value.sync="title"
      :maxLength="maxLength"
      :isFocusOnMount="true"
      @onEnterKey="onCompleteAddWrite"
    />
    <p class="desc_tip">
      <em :class="{ on: countingValue != 0 }">{{ countingValue }}</em> &#47; 30
    </p>
  </div>
</template>

<script>
import Input from "@/components/common/input/Input";
import { HANDLE_TITLE_MAX_LENGTH } from "@/constants/options";

export default {
  name: "HandleWriteItem",
  components: {
    Input,
  },
  data() {
    return {
      title: "",
    };
  },
  computed: {
    maxLength() {
      return HANDLE_TITLE_MAX_LENGTH;
    },
    countingValue() {
      return this.title.length;
    },
  },
  methods: {
    onCompleteAddWrite() {
      this.$emit("onCompleteAddWrite", this.title);
    },
  },
};
</script>
