<template>
  <tr>
    <td colspan="2">
      <CheckboxItem
        :id="rowData.cateNum"
        :text="rowData.title"
        :checkedNames.sync="checkedNamesSync"
      />
    </td>
    <!-- <td>{{ rowData.title }}</td> -->
  </tr>
</template>

<script>
import CheckboxItem from "@/components/common/checkbox/CheckboxItem";

export default {
  name: "CategoryListLine",
  components: {
    CheckboxItem,
  },
  props: {
    checkedNames: Array,
    rowData: {
      cateNum: Number,
      cateDate: String,
      title: String,
    },
  },
  computed: {
    checkedNamesSync: {
      get() {
        return this.checkedNames;
      },
      set(value) {
        this.$emit("update:checkedNames", value);
      },
    },
  },
};
</script>
<style scoped>
.tbl_handle tbody td {
  padding: 0;
  vertical-align: middle;
}
.tbl_handle tbody td .check_comm {
  display: block;
  padding: 15px 0;
}
</style>
